/* eslint-disable max-len */
export default {
    about: {
        title: `Інтернет Аптека 24 - замовлення ліків по всій Україні`,
        description: `Онлайн аптека "Аптека24" - це широкий асортимент, низькі ціни на ліки. Отримати своє замовлення можна в аптеці нашої мережі в цей же або на наступний день, в аптеці партнера - на наступний день. Всі медичні препарати мають сертифікати якості. Телефонуйте за безкоштовним номером 0800 30 22 44, і ви отримаєте детальну інформацію про наявність ліків в аптеках вашого міста. Ми працюємо для вашого здоров'я!`,
        advantages: {
            title: `У чому переваги apteka24.ua?`,
            hugeAssortment: `Понад 20 тисяч медпрепаратів з каталогу можна купити в аптеці онлайн. Тут представлені як популярні, так і рідкісні лікарські засоби, які важко знайти в більшості аптечних пунктів. Перелік доступних товарів постійно розширюється новими найменуваннями.`,
            delivery: `Інтернет аптека з доставкою в Україні. Замовлення можна отримати в найкоротші терміни в будь-якому населеному пункті за допомогою кур'єрських служб Укрпошта, «Нова Пошта» и Justin.`,
            discounts: `Знижки на потрібні ліки. Ціна знижується в осінньо-зимовий сезон на протизастудні ліки, а влітку - на протиалергічні.`,
            manufacturersCooperation: `Співпраця з найбільшим дистриб'ютором ліків. Ви можете придбати ліки в інтернет аптеці 24 безпосередньо від виробника.`,
            qualityCertificates: `Продукція має сертифікати якості, які можна замовити в аптеці, щоб переконатися в надійності при отриманні.`,
            medicalMarket: `Більше, ніж просто аптека. Це МедМаркет здоров'я з приємним шопінгом, інформативним і корисним блогом, а через сайт можна записатися онлайн на прийом до лікаря або замовити консультацію лікаря.`
        },
        assortment: {
            title: `Які товари можна придбати в інтернет аптеці 24?`,
            productsFromCatalog: `Продукція, представлена в каталозі, є в наявності на складі. Ви можете звернутися до нас з готовим рецептом лікаря, який оператори apteka24.ua розшифрують і сформують точне замовлення. Або ж Ви можете самостійно підібрати потрібні ліки і замовити в аптеці онлайн.`,
            productsHasInstruction: `Всі медикаменти містять точне зображення упаковки препарату і докладну інструкцію з усією необхідною медичною інформацією. Це дозволить легко визначитися з вибором.`,
            youCanFindAllDrugs: `У Вас є можливість купити всі необхідні ліки в інтернет аптеці, знайшовши їх в широкому асортименті медикаментів і товарів для здоров'я та краси онлайн:`,
            drugList: `<li>препарати від застуди, антибіотики, гінекологічні, гематологічні та гормональні препарати, засоби від алергії, для красивої шкіри, серцево-судинної системи, гепатопротектори, препарати для нервової системи і знеболюючі засоби;</li>
                    <li>вітаміни і БАДи для міцного здоров'я;</li>
                    <li>продукція для мам і дітей (пристосування для годування, продукція, що підвищує лактацію, і різноманітні дитячі чаї);</li>
                    <li>косметика і гігієнічна продукція по догляду за собою;</li>
                    <li>товари медичного призначення (медична техніка, маніпуляційні, діагностичні та перев'язувальні засоби, ортопедичні пристрої, компресійний трикотаж і медичний одяг);</li>
                    <li>продукція для здоров'я очей (контактні лінзи і краплі для очей);</li>
                    <li>спортивне харчування та інше.</li>`
        },
        howToGetOrder: {
            title: `Як отримати замовлення в онлайн аптеці?`,
            suggestMethods: `Як отримати замовлення в онлайн аптеці:`,
            suggestMethodsList: `<li>самовивіз з наших точок;</li>
                            <li>кур'єром мережі у великих містах;</li>
                            <li>службами доставки в будь-населені пункти України.</li>`,
            responsibility: `Коли Ви купите товар в нашій інтернет аптеці з доставкою, ми все перевіримо, переконаємося в якості, і тільки потім відправимо замовлення. Ми відповідаємо за все, що робимо.`
        }
    }
};
