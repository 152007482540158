/* eslint-disable max-len */
export default {
    about: {
        title: `Интернет Аптека 24 — заказ лекарств по всей Украине`,
        description: `Онлайн аптека "Аптека24" это широкий ассортимент, низкие цены на лекарства. Получить свой заказ можно в аптеке нашей сети в этот же или на следующий день, в аптеке партнера - на следующий день. Все мед препараты имеют сертификаты качества. Звоните по бесплатному номеру 0800 30 22 44, вы получите подробную информацию о наличии лекарств в аптеках вашего города. Мы работаем для вашего здоровья!`,
        advantages: {
            title: `В чем преимущества apteka24.ua?`,
            hugeAssortment: `Более 20 тысяч медпрепаратов из каталога можно купить в аптеке онлайн. Здесь представлены как популярные, так и редкие лекарственные средства, которые трудно найти в большинстве аптечных пунктов. Перечень доступных товаров постоянно расширяется новыми наименованиями.`,
            delivery: `Интернет аптека с доставкой в Украине. Заказ можно получить в кратчайшие сроки в любом населенном пункте при помощи курьерских служб Укрпошта, «Нова Пошта» и Justin.`,
            discounts: `Скидки на востребованные лекарства. Цена снижается в осенне-зимний сезон на противопростудные медикаменты, а летом — на противоаллергические.`,
            manufacturersCooperation: `Сотрудничество с крупнейшим дистрибьютором фармацевтических средств. Вы можете приобрести лекарство в интернет аптеке 24 напрямую от производителя.`,
            qualityCertificates: `Продукция обладает сертификатами качества, которые можно заказать в аптеке, чтобы убедиться в надежности при получении.`,
            medicalMarket: `Больше, чем просто аптека. Это медмаркет здоровья с приятным шопингом, информативным и полезным блогом, а через сайт можно записаться онлайн на прием к врачу или заказать консультацию доктора.`
        },
        assortment: {
            title: `Какие товары можно приобрести в интернет аптеке 24?`,
            productsFromCatalog: `Продукция, представленная в каталоге, есть в наличии на складе. Вы можете обратиться к нам с готовым рецептом врача, который операторы apteka24.ua расшифруют и сформируют точный заказ. Или же Вы можете самостоятельно подобрать нужные лекарства и заказать в аптеке онлайн.`,
            productsHasInstruction: `Все медикаменты содержат точное изображение упаковки препарата и подробную инструкцию со всей необходимой медицинской информацией. Это позволит легко определиться с выбором.`,
            youCanFindAllDrugs: `У Вас есть возможность купить все необходимые лекарства в интернет аптеке, найдя их в широком ассортименте медикаментов и товаров для здоровья и красоты онлайн:`,
            drugList: `<li>препараты от простуды, антибиотики, гинекологические, гематологические и гормональные препараты, средства от аллергии, для красивой кожи, сердечно-сосудистой системы, гепатопротекторы, препараты для нервной системы и обезболивающие средства;</li>
                    <li>витамины и БАДы для крепкого здоровья;</li>
                    <li>продукция для мам и детей (приспособления для кормления, продукция, повышающая лактацию, и разнообразные детские чаи);</li>
                    <li>косметика и гигиеническая продукция по уходу за собой;</li>
                    <li>товары медицинского назначения (медицинская техника, манипуляционные, диагностические и перевязочные средства, ортопедические приспособления, компрессионный трикотаж и медицинская одежда);</li>
                    <li>продукция для здоровья глаз (контактные линзы и капли для глаз);</li>
                    <li>спортивное питание и прочее.</li>`
        },
        howToGetOrder: {
            title: `Как получить заказ в онлайн аптеке?`,
            suggestMethods: `Мы предлагаем следующие способы получения заказов:`,
            suggestMethodsList: `<li>самовывоз из наших точек;</li>
                            <li>курьером сети в крупных городах;</li>
                            <li>службами доставки в любые населенные пункты Украины.</li>`,
            responsibility: `Когда Вы купите товар в нашей интернет аптеке с доставкой, мы все проверим, убедимся в качестве, и только потом отправим заказ. Мы отвечаем за все, что делаем.`
        }
    }
};
