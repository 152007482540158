/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    title: `Медичні експерти`,
    description: `<p>apteka24.ua співпрацює з висококваліфікованими медичними експертами з метою забезпечення надійності, релевантності, достовірності та наукової доказовості всіх матеріалів, опублікованих на сайті apteka24.ua. Ми приділяємо особливу увагу тому, щоб зміст контенту, товари та послуги відповідали найвищим медичним стандартам.</p>
                <p>Зміни медичної галузі, поява нових наукових даних і сучасні відкриття вчених стають причиною того, що в мережі стає занадто багато застарілої та непідтвердженої інформації, яка може вводити в оману. Ми вважаємо, що у медицині та в питаннях здоров'я це неприпустимо.</p>
                <p>Тому ми зібрали спеціальну команду висококваліфікованих лікарів різних спеціалізацій та фармацевтів, які разом з нами мають мету надати людям можливість отримувати тільки точну, достовірну, науково доведену, обґрунтовану, актуальну і релевантну інформацію медичного і наукового характеру.</p>
                <p>Весь контент на сайті apteka24.ua (як давно існуючий, так і новий) перевірений, відкоректований, оновлений в міру надходження нових наукових і офіційних даних, а також затверджений нашими медичними експертами.</p>
                <p>Інструкції, описи, характеристики препаратів, а також статті блогу і тексти категорій каталогу перевіряють досвідчені лікарі і фармацевти, які спеціалізуються в області, що висвітлюється в кожному конкретному виді контенту.</p>
                <p>Редакторська команда медичних експертів виступає цензорами сайту apteka24.ua, створює експертні матеріали та висвітлює проблемні питання здоров'я в докладних інтерв'ю.</p>`,
    medicalCensorsOfMedicines: `<h2>Медичні цензори лікарських засобів</h2>
                <p>Невід'ємна частина команди apteka24.uа — це фармацевти і фармацевти, які виступають медичними цензорами інформації, що надається на сайті apteka24.ua у якості описів для лікарських засобів і медичних товарів.</p>
                <p>Фахівці з фармакології перевіряють інструкції до препаратів і товарів медичного призначення, їх опис, характеристики та підбір аналогів, а також стежать за тим, щоб всі інструкції на сайті відповідали офіційному джерелу —<a href="${links.stateRegisterOfDrugs}" target="_blank"> Державному реєстру лікарських засобів України</a>.</p>
                <p>Ми вважаємо, що інструкція до лікарського засобу — це його «паспорт», це керівництво по використанню і ключ до здоров'я. А тому точність, повнота і доступність інструкції вкрай важливі — і саме тому ми забезпечуємо верифікацією усіх карток товарів згідно з<a href="${links.editorialPolicy}" target="_blank"> редакційною політикою </a>apteka24.ua.</p>`,
    medicalHealthInformationCensors: `<h2>Медичні цензори інформування з питань здоров'я</h2>
                <p>Оскільки зміни у медичній галузі відбуваються дуже швидко, з'являються нові наукові дані, а попередні положення з часом відкидаються, то весь контент apteka24.ua, що інформує з питань здоров'я, проходить обов'язкову перевірку та затвердження медичними цензорами, в ролі яких виступають висококваліфіковані лікарі з великими науковими знаннями та багаторічним практичним досвідом.</p>
                <p>Команда медичних експертів гарантує, що інформація в контенті блогу точна, актуальна та орієнтована на пацієнта.</p>
                <p>Відповідно до<a href="${links.editorialPolicy}" target="_blank"> редакційної політики</a>, статті блогу з питань медицини та здоров'я проходять обов'язкову перевірку та коректуру з боку медичних експертів. Перевірку і затвердження статей здійснює лікар відповідної компетенції, що спеціалізується на питаннях медичної галузі, до якої відноситься стаття.</p>
                <p>При висвітленні спірних тверджень, які не мають на цей час доказових наукових даних, ми посилаємося на думку і схвалення лікаря відповідної компетенції.</p>
                <p>Також лікарі з команди медичних експертів apteka24.ua ведуть просвітницьку діяльність, публікують власні авторські наукові матеріали та висвітлюють найбільш важливі питання здоров'я в інтерв'ю.</p>`
};
