/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    informationInTheReview: `Отзывы покупателей, размещенные на данной странице, носят сугубо информационный характер,
        предназначены для ознакомительных целей и являются личным мнением, а не медицинской рекомендацией.
        Не используйте отзывы покупателей в качестве руководства к действию и показанием для лечения
    `,
    informationInTheInstruction: `Инструкция, размещенная на данной странице, носит информационный характер и предназначена
        исключительно для ознакомительных целей. Не используйте данную инструкцию в качестве медицинских
        рекомендаций
    `,
    establishingDiagnosis: "Постановка диагноза и выбор методики лечения осуществляется только вашим лечащим врачом",
    reviewsPostingPolicy: `Все отзывы и комментарии публикуются в соответствии с <a href="${links.reviewsPostingPolicy}" target="_blank">Политикой публикации комментариев и отзывов пользователей сайта apteka24.ua</a>`,
    refusingOfResponsibility: `ООО "Аптека24" не несет ответственности за возможные негативные последствия, возникшие в результате использования информации, размещенной на сайте tov-apteka24.com.ua. Подробнее об <a href="${links.responsibility}" target="_blank">Отказе от ответственности</a>`,
    productAppearance: "Внешний вид товара может отличаться от изображённого",
    thermolabile: "В вашем заказе есть препарат, который требует особых условий хранения и транспортировки! Транспортировать эти препараты мы рекомендуем в термобоксе, хранить в холодильнике! Купить термобокс и холодовые элементы можно в аптеке.",
    prescription: "В вашем заказе есть препарат, который продаётся по рецепту. При выдаче заказа вас могут попросить предоставить рецепт, поэтому не забудьте взять его с собой.",
    lastUpdateDateDescription: "Цена и наличие актуальны на <time dateTime={0}>{1}</time> и действуют только для покупки онлайн",
    limitationSaleOfDrugsByAge: {
        title: "Продажа лекарственных средств осуществляется с 14 лет",
        description: "Для заказа товара с ограничением по возрасту потребуеся действующий документ для подтверждения возраста на момент доставки"
    }
};
