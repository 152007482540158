/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    morkovkiBeta: `Морковки`,
    workTime: `с 8:00 до 21:00 без выходных, <br /> бесплатно в пределах Украины!`,
    licenseGiven: `Лицензия выдана ГСУпоЛС №193025 от 18.06.2013г.`,
    selfMedication: "Самолікування може бути шкідливим для Вашого здоров'я",
    consult: "Перед застосуванням препарату проконсультуйтесь з лікарем",
    license: `<span>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ АПТЕКА24</span><br /><span>код ЕГРПОУ 36573533</span><br /><span>Дата регистрации 10.07.2009</span>`,
    copyright: `© 2013-2021 ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ ООО «АПТЕКА24». Информация, размещенная на сайте tov-apteka24.com.ua, предназначена исключительно для ознакомительных целей. Не используйте информацию с этого сайта для диагностики, лечения или профилактики заболеваний. Постановка диагноза и выбор методики лечения осуществляется только вашим лечащим врачом! <a href=${links.responsibility}>Подробнее</a> об отказе от ответственности.`,
    educationalPartner: `Наш
                        <br /> образовательный
                        <br /> партнер`,
    medicalPartner: `Наш
                    <br /> медицинский
                    <br /> партнер`
};
