/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    informationInTheReview: `Відгуки покупців, розміщені на даній сторінці, носять суто інформаційний характер,
         призначені для ознайомлювальних цілей і є особистою думкою, а не медичною рекомендацією.
         Не використовуйте відгуки покупців в якості керівництва до дії і показанням для лікування
    `,
    informationInTheInstruction: `Інструкція, розміщена на цій сторінці, носить інформаційний характер і призначена
         виключно з метою ознайомлення. Не використовуйте цей посібник як медичних
         рекомендацій
    `,
    establishingDiagnosis: "Постановка діагнозу і вибір методики лікування здійснюється тільки вашим лікарем",
    reviewsPostingPolicy: `Всі відгуки та коментарі публікуються відповідно до <a href="${links.reviewsPostingPolicy}" target="_blank">Політики публікації коментарів і відгуків користувачів сайту apteka24.ua</a>`,
    refusingOfResponsibility: `ТОВ "Аптека24" не несе відповідальності за можливі негативні наслідки, що виникли в результаті використання інформації, розміщеної на сайті tov-apteka24.com.ua. Детальніше про <a href="${links.responsibility}" target="_blank">Відмову від відповідальності</a>`,
    productAppearance: "Зовнішній вигляд товару може відрізнятися від зображеного",
    thermolabile: "У вашому замовленні є препарат, який вимагає особливих умов зберігання і транспортування! Транспортувати ці препарати ми рекомендуємо в термобоксах, зберігати в холодильнику! Купити термобокс і холодові елементи можна в аптеці.",
    prescription: "У вашому замовленні є препарат, який продається за рецептом. При видачі замовлення вас можуть попросити надати рецепт, тому не забудьте взяти його з собою.",
    lastUpdateDateDescription: "Ціна та наявність актуальні на <time dateTime={0}>{1}</time> і діють тільки для покупки онлайн",
    limitationSaleOfDrugsByAge: {
        title: "Продаж лікарських засобів здійснюється із 14 років.",
        description: "Для замовлення товару з обмеженням за віком потрібен діючий документ для підтвердження віку на момент доставки"
    }
};
