class MobileNavigation {
    constructor(props) {
        this.strings = props.strings;
        this.env = props.env;
        this.links = props.links;

        this.NavigationItemEntity = props.NavigationItemEntity;
    }

    /**
     * @public
     * @method getNavigation
     * @returns {Navigation[]}
     */
    getNavigation() {
        let navigationItems = [
            // {
            //     url: this.links.howWeWork,
            //     title: this.strings.howWeWork,
            //     icon: "icon-question"
            // },
            {
                url: `${this.env.getBitrixHost()}/about/delivery/`,
                title: this.strings.deliveryAndPayment,
                icon: "icon-delivery"
            },
            {
                url: `${this.env.getBitrixHost()}/about/warranty/`,
                title: this.strings.qualityAssurance,
                icon: "icon-quality"
            },
            {
                url: `${this.env.getBitrixHost()}/order-return/`,
                title: this.strings.returnConditions,
                icon: "icon-returns"
            },
            {
                url: `${this.env.getBitrixHost()}/about/`,
                title: this.strings.aboutUs,
                icon: "icon-awesome"
            },
            {
                url: `${this.env.getBitrixHost()}/pharmacy/`,
                title: this.strings.drugstores,
                icon: "icon-shop"
            }
        ];

        return navigationItems.map((item) => new this.NavigationItemEntity(item));
    }
}

export default MobileNavigation;
