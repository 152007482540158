/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    title: `Ліки при вагітності та годуванні груддю: дізнайтеся, як препарати впливають на перебіг вагітності та здоров'я малюка`,
    subtitle: `Наша команда створила цей довідник, щоб ви могли знайти інформацію про вплив лікарських засобів на стан вагітної жінки, а також на немовля в період грудного вигодовування`,
    drugs: {
        title: `Лікарські препарати, інформацію про які найчастіше шукають у період вагітності та годування груддю`
    },
    warning: {
        consult: `Завжди консультуйтеся зі своїм лікарем, щоб переконатися, що інформація, яка відображається на цій сторінці, враховує індивідуальні особливості вашого організму і стану. Постановка діагнозу і вибір методики лікування здійснюється тільки вашим лікарем!`,
        responsibility: `ТОВ "Аптека24" не несе відповідальності за наслідки, що виникли внаслідок використання інформації, розміщеної на сайті www.tov-apteka24.com.ua. Детальніше про <a href="${links.responsibility}" target="_blank">Відмову від відповідальності</a>.`
    },
    recommendations: {
        title: `Рекомендуємо ознайомитися`
    },
    contraindications: `Більшість лікарських засобів протипоказані або небажані для застосування під час вагітності через їх несприятливий вплив на перебіг вагітності і стан плода. Це стосується не тільки рецептурних, але також і безрецептурних препаратів і натуральних добавок. Тому кожна майбутня мама повинна знати про особливості будь-яких ліків, які вона приймає, щоб уникнути негативного впливу на дитину. Пам'ятайте, що перший триместр вагітності - найбільш критичний період, коли застосування більшості ліків заборонено для вагітних.`,
    dangers: `Крім того, матері, які годують груддю, повинні пам'ятати, що діючі речовини ліків можуть проникати в грудне молоко і таким чином потрапляти в організм немовляти, надаючи небажаний вплив на його здоров'я. Застосування деяких ліків може бути протипоказано в період грудного вигодовування.`
};
